* { margin: 0; padding: 0; }
html {
  }

body {
    background-color: #090a0a;
    margin:0 ;
}
#yellowbar {
    width: 100%;
    height: 5px;
    background-color: #f6c345;
}
#topbar {
    height: 90px;
}
#side_bar {
	float: left;
	z-index: 999;
}

#logo {
    float: left;
    margin-top: 15px;
    margin-left: 50px;
    background-image: url("../images/logo.png");
    width: 70px;
    height: 70px;
}
#top_fake {
    float: right;
    margin-top: 30px;
    margin-right: 50px;
    background-image: url("../images/top_fake.png");
    width: 891px;
    height: 70px;
}
#main_heading {
    margin-left: 170px;
    margin-bottom: 20px;
    color: silver;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5em;
    font-weight: bold;
}
#map {
	height: 700px;
    width: 100%-220px;
    margin-right: 50px;
    margin-left: 170px;
}
#legend {
	font-family: Arial, sans-serif;
	background: #fff;
	padding: 10px;
	margin: 10px;
	border: 3px solid #000;
	bottom: 400px !important;
}
#legend h3 {
	margin-top: 0;
}
#legend img {
	vertical-align: middle;
}

#dashboard_fake {
	height: 750px;
    width: 100%-220px;
    margin-right: 50px;
    margin-left: 170px;
    background-size: contain;
    background-image: url("../images/dashboard.png");
    background-repeat: no-repeat;
}

#vitals_fake {
	height: 750px;
    width: 100%-220px;
    margin-right: 50px;
    margin-left: 170px;
    background-size: contain;
    background-image: url("../images/vitals.png");
    background-repeat: no-repeat;
}
#customize_fake {
	height: 750px;
    width: 100%-220px;
    margin-right: 50px;
    margin-left: 170px;
    background-size: contain;
    background-image: url("../images/customize.png");
    background-repeat: no-repeat;
}
#glassscreen {
  height:100%;
  width:100%;
  background: url('../images/screen.png') no-repeat center center;
  background-size: auto 100%;
  float:left;
  margin: 0 auto;
  position:relative;
}

#glassscreen_take_control {
  height:700px;
/*  width:100%;*/
    width: 100%-220px;
    margin-right: 50px;
    margin-left: 170px;
  background: url('../images/screen.png') no-repeat center center;
  background-size: auto 100%;
  margin: 20px auto;
  position:relative;
	left: 170px;
}

#glassscreen_history {
  height:80vh;
  width:75%;
  background: url('../images/screen.png') no-repeat center center;
  background-size: auto 100%;
  float:left;
  margin: 0 auto;
  position:relative;
}

#parameters
{
	position:absolute;
	float:left;
	font-family: "Cambria", "Hoefler Text", "Liberation Serif", "Times", "Times New Roman", "serif";
	color: #ffdc00;
	font-size: 1.8vh;
}

#parameters_history
{
	position:absolute;
	float:left;
	font-family: "Cambria", "Hoefler Text", "Liberation Serif", "Times", "Times New Roman", "serif";
	color: #ffdc00;
	font-size: 1.8vh;
}

#records {
	font-family: "Gotham", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
	font-size: 1.5vh;
	width: 23%;
	float: left;
	margin-top: 10px;
	margin-left: 10px;
}

.dataTables_length, .dataTables_filter, .dataTables_info, .dataTables_paginate, #play_records_paginate, #play_records_next, #play_records_previous  {
	color: #ffdc00 !important;
}

.paginate_button {
	background-color: #999999;
}

.next, .previous{
	background-color: transparent;
}

#play_records tr{
	cursor: pointer;
	background-color: #eeeeee;
}

#play_records tr:hover{
	cursor: pointer;
	background-color: #efeed5;
}

.play_records_selected{
	background-color: #efdde5 !important;
}

#play_records {
	padding-top: 10px;
}

#slider
{
	position:absolute;
	float:left;
	display:block;
	top: 100%;
	width:90%;
	padding-left: 100px;
	margin-top: 5px;
}

.irs-bar{
	background: #3F8E9B !important;
}

.irs-single{
	background-color: #15555F !important;
	font-size: 1.2vh !important;
}

.irs-single:before{
	border-top-color: #15555F !important;
	font-size: 1.2vh !important;
}

.irs-min, .irs-max {
	font-size: 1.2vh !important;
	color: #000 !important;
}

.player_btn {
	border: none;
	color: white;
	background-color: white;
	cursor: pointer;
	float:left;
	width: 55px;
	padding-left: 5px;
	padding-right: 5px;
}

.player_btn:focus {
  outline: none;
}

.player {
	display: table;
	margin: 0 auto;
}

.btn_highlight {
	background: rgba(241,232,106,1);
	background: -moz-radial-gradient(center, ellipse cover, rgba(241,232,106,1) 0%, rgba(245,230,97,0) 75%, rgba(250,228,87,0) 100%);
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(241,232,106,1)), color-stop(75%, rgba(245,230,97,0)), color-stop(100%, rgba(250,228,87,0)));
	background: -webkit-radial-gradient(center, ellipse cover, rgba(241,232,106,1) 0%, rgba(245,230,97,0) 75%, rgba(250,228,87,0) 100%);
	background: -o-radial-gradient(center, ellipse cover, rgba(241,232,106,1) 0%, rgba(245,230,97,0) 75%, rgba(250,228,87,0) 100%);
	background: -ms-radial-gradient(center, ellipse cover, rgba(241,232,106,1) 0%, rgba(245,230,97,0) 75%, rgba(250,228,87,0) 100%);
	background: radial-gradient(ellipse at center, rgba(241,232,106,1) 0%, rgba(245,230,97,0) 75%, rgba(250,228,87,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1e86a', endColorstr='#fae457', GradientType=1 );
}

.r_btn_highlight {
	background: rgba(66,244,244,1);
	background: -moz-radial-gradient(center, ellipse cover, rgba(66,244,244,1) 0%, rgba(245,230,97,0) 75%, rgba(250,228,87,0) 100%);
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(66,244,244,1)), color-stop(75%, rgba(245,230,97,0)), color-stop(100%, rgba(250,228,87,0)));
	background: -webkit-radial-gradient(center, ellipse cover, rgba(66,244,244,1) 0%, rgba(245,230,97,0) 75%, rgba(250,228,87,0) 100%);
	background: -o-radial-gradient(center, ellipse cover, rgba(66,244,244,1) 0%, rgba(245,230,97,0) 75%, rgba(250,228,87,0) 100%);
	background: -ms-radial-gradient(center, ellipse cover, rgba(66,244,244,1) 0%, rgba(245,230,97,0) 75%, rgba(250,228,87,0) 100%);
	background: radial-gradient(ellipse at center, rgba(66,244,244,1) 0%, rgba(245,230,97,0) 75%, rgba(250,228,87,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42f4f4', endColorstr='#fae457', GradientType=1 );
}

#up_btn {
	-webkit-mask-box-image: url(../images/up_btn.png);
	background-color: white;
}
#down_btn {
	-webkit-mask-box-image: url(../images/down_btn.png);
	background-color: white;
}
#left_btn {
	-webkit-mask-box-image: url(../images/left_btn.png);
	background-color: white;
}
#right_btn {
	-webkit-mask-box-image: url(../images/right_btn.png);
	background-color: white;
}
#go_btn {
	-webkit-mask-box-image: url(../images/go_btn.png);
	background-color: white;
}
#stop_btn {
	-webkit-mask-box-image: url(../images/stop_btn.png);
	background-color: white;
}
#home_btn {
	-webkit-mask-box-image: url(../images/home_btn.png);
	background-color: white;
}
#preset_btn {
	-webkit-mask-box-image: url(../images/preset_btn.png);
	background-color: white;
}
#update_btn {
	-webkit-mask-box-image: url(../images/update_btn.png);
	background-color: white;
}
#w810_btn {
	-webkit-mask-box-image: url(../images/w810_btn.png);
	background-color: white;
}
#dual_btn {
	-webkit-mask-box-image: url(../images/dual_btn.png);
	background-color: white;
}
#w980_btn {
	-webkit-mask-box-image: url(../images/w980_btn.png);
	background-color: white;
}
#haptic_btn {
	-webkit-mask-box-image: url(../images/haptic_btn.png);
	background-color: white;
}
#aiming_btn {
	-webkit-mask-box-image: url(../images/aiming_btn.png);
	background-color: white;
}
#tip_btn {
	-webkit-mask-box-image: url(../images/tip_btn.png);
	background-color: white;
}
#volume_btn {
	-webkit-mask-box-image: url(../images/sound_btn.png);
	background-color: white;
}
#wifi_btn {
	-webkit-mask-box-image: url(../images/wifi_btn.png);
	background-color: white;
}
#menu_btn {
	-webkit-mask-box-image: url(../images/menu_btn.png);
	background-color: white;
}
#voice_btn {
	-webkit-mask-box-image: url(../images/voice_btn.png);
	background-color: white;
}

#up_btn_touched {
	height:5%;
	position:absolute;
	float:left;
	display:block;
	top: 81.3%;
}
#down_btn_touched {
	height:5%;
	position:absolute;
	float:left;
	display:block;
	top: 95.2%;
}
#left_btn_touched {
	height:8.5%;
	position:absolute;
	float:left;
	display:block;
	top: 86.5%;
}
#right_btn_touched {
	height:8.5%;
	position:absolute;
	float:left;
	display:block;
	top: 86.5%;
}
#go_btn_touched {
	height:8%;
	position:absolute;
	float:left;
	display:block;
	top: 86.8%;
}
#stop_btn_touched {
	height:8%;
	position:absolute;
	float:left;
	display:block;
	top: 86.8%;
}
#home_btn_touched {
	height:8%;
	position:absolute;
	float:left;
	display:block;
	top: 75.9%;
}
#preset_btn_touched {
	height:8.9%;
	position:absolute;
	float:left;
	display:block;
	top: 84%;
}
#update_btn_touched {
	height:7.6%;
	position:absolute;
	float:left;
	display:block;
	top: 92.8%;
}
#w810_btn_touched {
	height:5.6%;
	position:absolute;
	float:left;
	display:block;
	top: 75.9%;
}
#dual_btn_touched {
	height:5.6%;
	position:absolute;
	float:left;
	display:block;
	top: 75.9%;
}
#w980_btn_touched {
	height:5.6%;
	position:absolute;
	float:left;
	display:block;
	top: 75.9%;
}
#haptic_btn_touched {
	height:8.9%;
	position:absolute;
	float:left;
	display:block;
	top: 84%;
}
#aiming_btn_touched {
	height:7.6%;
	position:absolute;
	float:left;
	display:block;
	top: 92.8%;
}
#tip_btn_touched {
	height:8.9%;
	position:absolute;
	float:left;
	display:block;
	top: 84%;
}
#volume_btn_touched {
	height:7.6%;
	position:absolute;
	float:left;
	display:block;
	top: 92.8%;
}
#wifi_btn_touched {
	height:8%;
	position:absolute;
	float:left;
	display:block;
	top: 75.9%;
}
#menu_btn_touched {
	height:8.9%;
	position:absolute;
	float:left;
	display:block;
	top: 84%;
}
#voice_btn_touched {
	height:7.6%;
	position:absolute;
	float:left;
	display:block;
	top: 92.8%;
}

.cropped_segments {
	background-size: auto 100% !important;
	position:absolute;
	display: none;
	float:left;
}

#aiming_tip {
  	background: url('../images/aiming_tip.png') no-repeat;
}

#illumination_tip {
  	background: url('../images/illumination_tip.png') no-repeat;
}

#stop_button {
  	background: url('../images/stop_button.png') no-repeat;
}

#on_button {
  	background: url('../images/on_button.png') no-repeat;
}

#footpedal_pressed {
  	background: url('../images/footpedal_pressed.png') no-repeat;
}

#Hemostasis {
  	background: url('../images/Hemostasis.png') no-repeat;
}

#Debridement {
  	background: url('../images/Debridement.png') no-repeat;
}

#HerpeticUlcer {
  	background: url('../images/HerpeticUlcer.png') no-repeat;
}

#AphtousUlcer {
  	background: url('../images/AphtousUlcer.png') no-repeat;
}

#Decontamination1 {
  	background: url('../images/Decontamination1.png') no-repeat;
}

#Decontamination2 {
}

#NonSurgical1 {
  	background: url('../images/NonSurgical1.png') no-repeat;
}

#NonSurgical2 {
}

#NonSurgical3 {
}

#Laser810Line {
  	background: url('../images/Laser810Line.png') no-repeat;
}

#Laser810Active1 {
  	background: url('../images/Laser810Active1.png') no-repeat;
}

#Laser810Active2 {
}

#Laser810Wavelength1 {
  	background: url('../images/Laser810Wavelength1.png') no-repeat;
}

#Laser810Wavelength2 {
}

#Laser810Wavelength3 {
}

#Laser810Standby {
  	background: url('../images/Laser810Standby.png') no-repeat;
}

#SoundHigh {
  	background: url('../images/SoundHigh.png') no-repeat;
}

#SoundMedium {
  	background: url('../images/SoundMedium.png') no-repeat;
}

#SoundLow {
  	background: url('../images/SoundLow.png') no-repeat;
}

#SoundMute {
  	background: url('../images/SoundMute.png') no-repeat;
}

#SoundIcon {
  	background: url('../images/SoundIcon.png') no-repeat;
}

#UltradentLogo {
  	background: url('../images/UltradentLogo.png') no-repeat;
}

#SpeakerIcon {
  	background: url('../images/SpeakerIcon.png') no-repeat;
}

#SpeakerMute {
  	background: url('../images/SpeakerMute.png') no-repeat;
}

#SpeakerLow {
  	background: url('../images/SpeakerLow.png') no-repeat;
}

#SpeakerMedium {
  	background: url('../images/SpeakerMedium.png') no-repeat;
}

#SpeakerHigh {
  	background: url('../images/SpeakerHigh.png') no-repeat;
}

#RemoteInterlock {
  	background: url('../images/RemoteInterlock.png') no-repeat;
}

#WifiLow {
  	background: url('../images/WifiLow.png') no-repeat;
}

#Power5 {
  	background: url('../images/Power5.png') no-repeat;
}

#Power4 {
  	background: url('../images/Power4.png') no-repeat;
}

#Power3 {
  	background: url('../images/Power3.png') no-repeat;
}

#Power2 {
  	background: url('../images/Power2.png') no-repeat;
}

#Power1 {
  	background: url('../images/Power1.png') no-repeat;
}

#Power6 {
  	background: url('../images/Power6.png') no-repeat;
}

#Power7 {
  	background: url('../images/Power7.png') no-repeat;
}

#Power8 {
  	background: url('../images/Power8.png') no-repeat;
}

#Power9 {
  	background: url('../images/Power9.png') no-repeat;
}

#Power10 {
  	background: url('../images/Power10.png') no-repeat;
}

#Laser100WPeak {
  	background: url('../images/Laser100WPeak.png') no-repeat;
}

#LeftDigitTop {
  	background: url('../images/LeftDigitTop.png') no-repeat;
}

#LeftDigitTopLeft {
  	background: url('../images/LeftDigitTopLeft.png') no-repeat;
}

#LeftDigitCenter {
  	background: url('../images/LeftDigitCenter.png') no-repeat;
}

#LeftDigitBottomLeft {
  	background: url('../images/LeftDigitBottomLeft.png') no-repeat;
}

#LeftDigitBottom {
  	background: url('../images/LeftDigitBottom.png') no-repeat;
}

#LeftDigitBottomRight {
  	background: url('../images/LeftDigitBottomRight.png') no-repeat;
}

#LeftDigitTopRight {
  	background: url('../images/LeftDigitTopRight.png') no-repeat;
}

#VersionIcon {
  	background: url('../images/VersionIcon.png') no-repeat;
}

#DotIcon {
  	background: url('../images/DotIcon.png') no-repeat;
}

#Surgical1 {
  	background: url('../images/Surgical1.png') no-repeat;
}

#Surgical2 {
}

#Surgical3 {
}

#ImplantRecovery {
  	background: url('../images/ImplantRecovery.png') no-repeat;
}

#ToothExposure {
  	background: url('../images/ToothExposure.png') no-repeat;
}

#Gingivectomy {
  	background: url('../images/Gingivectomy.png') no-repeat;
}

#Frenectomy {
  	background: url('../images/Frenectomy.png') no-repeat;
}

#PassError1 {
  	background: url('../images/PassError1.png') no-repeat;
}

#PassCheck1 {
  	background: url('../images/PassCheck1.png') no-repeat;
}

#PassCheck2 {
  	background: url('../images/PassCheck2.png') no-repeat;
}

#PassError2 {
  	background: url('../images/PassError2.png') no-repeat;
}

#EnterPasscode {
  	background: url('../images/EnterPasscode.png') no-repeat;
}

#PassCheck3 {
  	background: url('../images/PassCheck3.png') no-repeat;
}

#PassError3 {
  	background: url('../images/PassError3.png') no-repeat;
}

#PassCheck4 {
  	background: url('../images/PassCheck4.png') no-repeat;
}

#PassError4 {
  	background: url('../images/PassError4.png') no-repeat;
}

#Troughing {
  	background: url('../images/Troughing.png') no-repeat;
}

#Pulpotomy {
  	background: url('../images/Pulpotomy.png') no-repeat;
}

#Gingivoplasty {
  	background: url('../images/Gingivoplasty.png') no-repeat;
}

#Operculectomy {
  	background: url('../images/Operculectomy.png') no-repeat;
}

#IncisionExcision {
  	background: url('../images/IncisionExcision.png') no-repeat;
}

#TimerInSeconds {
  	background: url('../images/TimerInSeconds.png') no-repeat;
}

#AverageWatts {
  	background: url('../images/AverageWatts.png') no-repeat;
}

#RightDigitTopLeft {
  	background: url('../images/RightDigitTopLeft.png') no-repeat;
}

#RightDigitCenter {
  	background: url('../images/RightDigitCenter.png') no-repeat;
}

#RightDigitBottomLeft {
  	background: url('../images/RightDigitBottomLeft.png') no-repeat;
}

#RightDigitBottom {
  	background: url('../images/RightDigitBottom.png') no-repeat;
}

#RightDigitBottomRight {
  	background: url('../images/RightDigitBottomRight.png') no-repeat;
}

#RightDigitTopRight {
  	background: url('../images/RightDigitTopRight.png') no-repeat;
}

#RightDigitTop {
  	background: url('../images/RightDigitTop.png') no-repeat;
}

#Laser150WPeak {
  	background: url('../images/Laser150WPeak.png') no-repeat;
}

#PowerLineBottom {
  	background: url('../images/PowerLineBottom.png') no-repeat;
}

#Power11 {
  	background: url('../images/Power11.png') no-repeat;
}

#Power12 {
  	background: url('../images/Power12.png') no-repeat;
}

#Power13 {
  	background: url('../images/Power13.png') no-repeat;
}

#Power14 {
  	background: url('../images/Power14.png') no-repeat;
}

#Power15 {
  	background: url('../images/Power15.png') no-repeat;
}

#Power20 {
  	background: url('../images/Power20.png') no-repeat;
}

#Power19 {
  	background: url('../images/Power19.png') no-repeat;
}

#Power18 {
  	background: url('../images/Power18.png') no-repeat;
}

#Power17 {
  	background: url('../images/Power17.png') no-repeat;
}

#Power16 {
  	background: url('../images/Power16.png') no-repeat;
}

#PowerLineTop {
  	background: url('../images/PowerLineTop.png') no-repeat;
}

#BluetoothIcon {
  	background: url('../images/Bluetooth.png') no-repeat;
}

#BatteryIcon {
  	background: url('../images/BatteryIcon.png') no-repeat;
}

#ACIcon {
  	background: url('../images/ACIcon.png') no-repeat;
}

#BatteryLow {
  	background: url('../images/BatteryLow.png') no-repeat;
}

#BatteryMedium {
  	background: url('../images/BatteryMedium.png') no-repeat;
}

#BatteryHigh {
  	background: url('../images/BatteryHigh.png') no-repeat;
}

#BatteryFull {
  	background: url('../images/BatteryFull.png') no-repeat;
}

#FirmwareUpdate {
  	background: url('../images/FirmwareUpdate.png') no-repeat;
}

#WifiMedium {
  	background: url('../images/WifiMedium.png') no-repeat;
}

#WifiHigh {
  	background: url('../images/WifiHigh.png') no-repeat;
}

#AimingIcon {
  	background: url('../images/AimingIcon.png') no-repeat;
}

#AimingHigh {
  	background: url('../images/AimingHigh.png') no-repeat;
}

#AimingMedium {
  	background: url('../images/AimingMedium.png') no-repeat;
}

#AimingLow {
  	background: url('../images/AimingLow.png') no-repeat;
}

#AimingOff {
  	background: url('../images/AimingOff.png') no-repeat;
}

#Laser980Standby {
  	background: url('../images/Laser980Standby.png') no-repeat;
}

#Laser980Wavelength1 {
  	background: url('../images/Laser980Wavelength1.png') no-repeat;
}

#Laser980Wavelength2 {
}

#Laser980Wavelength3 {
}

#Laser980Active1 {
  	background: url('../images/Laser980Active1.png') no-repeat;
}

#Laser980Active2 {
}

#Laser980Line {
  	background: url('../images/Laser980Line.png') no-repeat;
}

#PainRelief1 {
  	background: url('../images/PainRelief1.png') no-repeat;
}

#PainRelief2 {
}

#PainRelief3 {
}

#Tip7mm {
  	background: url('../images/Tip7mm.png') no-repeat;
}

#Tip3mm {
  	background: url('../images/Tip3mm.png') no-repeat;
}

#Tip25mm {
  	background: url('../images/Tip25mm.png') no-repeat;
}

/*.modal {
    display:    none;
    position:   fixed;
    z-index:    1000;
    top:        0;
    left:       0;
    height:     100%;
    width:      100%;
    background: rgba( 0, 0, 0, .8 )
                url('../images/loading.gif')
                50% 50%
                no-repeat;
}*/

/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */
body.loading {
    overflow: hidden;
}

/* Anytime the body has the loading class, our
   modal element will be visible */
body.loading .modal {
    display: block;
}

.ui-footer {
    border-width: 0px 0 !important;
}

.ui-icon-loading {
    background: url(../images/ajax-loader.gif);
    background-size: 2.875em 2.875em;
}
.ui-loader-verbose {
	font-family: Helvetica, Arial, sans-serif /*{global-font-family}*/;
    width: 12.5em;
    filter: Alpha(Opacity=88);
    opacity: .88;
    box-shadow: 0 1px 1px -1px #fff;
    height: auto;
    margin-left: -6.875em;
    margin-top: -2.6875em;
    padding: .625em;
    background-color: #fff;
    border-color: #ddd;
    color: #333;
    text-shadow: 0 1px 0 #f3f3f3;
    border-radius: .3125em;
}

.next_btn {
	font-family: Helvetica, Arial, sans-serif /*{global-font-family}*/;
	background-color:#ffc20e;
	font-size: 1.5em !important;
	font-weight:bold;
	border-radius: 35px;
	border: 2px solid;
	margin-right:50px !important;
	margin-bottom:20px !important;
	width:150px !important;
    border: none;
    outline:none;

}
.next_btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.next_btn span:after {
  content: '»';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.next_btn:hover span {
  padding-right: 25px;
}

.next_btn:hover span:after {
  opacity: 1;
  right: 0;
}

#lastlogline
{
	display:none;
}

#hiddenpane
{
	overflow-y: scroll;
	height:400px;
	display:none;
}

#firmware
{
	height:28px;
	width:28px;
  	background: url('../images/firmware.png') center center no-repeat;
	background-size: auto 100%;
	position:absolute;
	display:block;
	left:40%;
	margin-left: 95px;
	margin-top: 50px;
	display:none;
}

#firmwaretext
{
	position:absolute;
	left:40%;
	margin-top:55px;
	margin-left: 160px;
	font-family: Helvetica, Arial, sans-serif /*{global-font-family}*/;
	font-size: 1em !important;
	font-weight:bold;
	display:none;
}

#batteryinfo1
{
	height:128px;
	width:128px;
  	background: url('../images/battery.png') center center no-repeat;
	background-size: auto 100%;
	position:absolute;
	display:none;
	left:40%;
	margin-left: 50px;
	margin-top: 300px;
}

#batteryinfotext
{
	position:absolute;
	left:40%;
	margin-top:320px;
	margin-left: 160px;
	font-family: Helvetica, Arial, sans-serif /*{global-font-family}*/;
	font-size: 1em !important;
	font-weight:bold;
	display:none;
}

#laser
{
	height:60px;
	width:128px;
  	background: url('../images/laser.png') center center no-repeat;
	background-size: auto 100%;
	position:absolute;
	display:none;
	left:40%;
	margin-left: 45px;
	margin-top: 200px;
}

#lasertext
{
	position:absolute;
	left:40%;
	margin-top:185px;
	margin-left: 160px;
	font-family: Helvetica, Arial, sans-serif /*{global-font-family}*/;
	font-size: 1em !important;
	font-weight:bold;
	display:none;
}

#sd
{
	height:60px;
	width:128px;
  	background: url('../images/sd.png') center center no-repeat;
	background-size: auto 100%;
	position:absolute;
	display:none;
	left:40%;
	margin-left: 45px;
	margin-top: 100px;
}

#sdtext
{
	position:absolute;
	left:40%;
	margin-top:120px;
	margin-left: 160px;
	font-family: Helvetica, Arial, sans-serif /*{global-font-family}*/;
	font-size: 1em !important;
	font-weight:bold;
	display:none;
}

#current
{
	height:60px;
	width:128px;
  	background: url('../images/current.png') center center no-repeat;
	background-size: auto 100%;
	position:absolute;
	display:none;
	left:40%;
	margin-left: 45px;
	margin-top: 450px;
}

#currenttext
{
	position:absolute;
	left:40%;
	margin-top:465px;
	margin-left: 160px;
	font-family: Helvetica, Arial, sans-serif /*{global-font-family}*/;
	font-size: 1em !important;
	font-weight:bold;
	display:none;
}
