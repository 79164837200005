.rangeslider-horizontal {
  height: 10px;
  border-radius: 10px;
  .rangeslider__fill {
    height: 100%;
    background-color: #ffc107;
    border-radius: 10px;
    top: 0;
  }
  .rangeslider__handle {
    width: 20px;
    height: 20px;
    border-radius: 30px;
    background-color: #ffc107;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    &:after {
      content: ' ';
      position: absolute;
      width: 0px;
      height: 0px;
      top: 6px;
      left: 6px;
      border-radius: 50%;
      background-color: #ffc107;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset,
        0 -1px 3px rgba(0, 0, 0, 0.4) inset;
    }
  }
  .rangeslider__handle-tooltip {
    top: -55px;
    &:after {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid rgba(0, 0, 0, 0.8);
      left: 50%;
      bottom: -8px;
      transform: translate3d(-50%, 0, 0);
    }
  }
}

.selected-row{
  background-color: yellow !important;
}
