$progress-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
.progress {
  -webkit-box-shadow: $progress-shadow;
  box-shadow: $progress-shadow;
  -moz-box-shadow: $progress-shadow;
  -o-shadow: $progress-shadow;
}

.vertical {
  margin-left: auto;
  margin-right: auto;
  width: 20px;
  transform: rotate(90deg);
}

.led-progress-label{
  h6{
    font-size:10px;
  }
}
